//import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface DocCheckLoginProps {
  loginId: string;
  language?: string;
  size?: 's_red' | 'm_red' | 'l_red' | 'xl_red';
}

const DocCheckLogin: React.FC<DocCheckLoginProps> = ({ 
  loginId, 
  language = 'en', 
  size = 'login_xl' 
}) => {
  const { t } = useTranslation();


  // Construct the DocCheck iframe URL
  const iframeUrl = `https://login.doccheck.com/code/${loginId}/${language}/${size}?auth_type=doccheck`;

  return (
    <div className="doccheck-login-container">
      <iframe 
        src={iframeUrl}
        width="467" 
        height="auto" 
        frameBorder="0" 
        scrolling="no" 
        name="dc_login_iframe"
        id="dc_login_iframe"
        title={t('doccheck.login.iframe.title')}
      >
        <a href={iframeUrl} target="_blank" rel="noopener noreferrer">
          {t('doccheck.login.link')}
        </a>
      </iframe>
    </div>
  );
};


export default DocCheckLogin;