// DocCheckLoginModal.tsx
import React from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DocCheckLogin from '../../component/DocCheck/DocCheckLogin';

interface DocCheckLoginModalProps {
  open: boolean;
  onClose: () => void;
}

const DocCheckLoginModal: React.FC<DocCheckLoginModalProps> = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <IconButton aria-label="close" onClick={onClose} style={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DocCheckLogin 
          loginId="2000000021488"
          language="de"
        />
      </DialogContent>
    </Dialog>
  );
};

export default DocCheckLoginModal;